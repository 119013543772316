import React, { useEffect, useState } from "react";
import ImageWithBasePath from "src/core/img/imagewithbasebath";
import Select from "react-select";
import { Link } from "react-router-dom";
import {
  Archive,
  Calendar,
  User,
  Trash2,
  Filter,
  // Sliders,
  Printer,
  X,
} from "react-feather";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import Table from "src/core/pagination/datatable";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  getTransferStockOut,
  getTransferStockOutDetail,
  reset,
  transferStockOutCutOff,
} from "src/redux/store/transferStockOut";
import Header from "./Header";
import AddModal from "./AddModal";
import { getWarehouse, getWarehouseGoods } from "src/redux/store/warehouse";
import PrintModal from "./PrintModal";

const renderStatus = (val) => {
  if (val == "created") return <span className="badge bg-warning">{val}</span>;
  if (val == "partially received")
    return <span className="badge bg-secondary">{val}</span>;
  if (val == "closed") return <span className="badge bg-success">{val}</span>;
  return <span className="badge bg-success">{val}</span>;
};

const StockTransfer = () => {
  //   const data = useSelector((state) => state.stocktransferdata);

  const store = JSON.parse(localStorage.getItem("store"));
  const transferStockOut = useSelector((state) => state.transferStockOut);
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [page, setPage] = useState(1);
  const [q, setQ] = useState("");
  const [search, setSearch] = useState("");

  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    dispatch(
      getTransferStockOut({
        limit,
        // offset: skip,
        page,
        stock_order_code: q,
        order: "stock_order_code",
      })
    );
  }, [limit, skip, q, dispatch, page]);

  useEffect(() => {
    dispatch(getWarehouseGoods());
  }, [dispatch]);

  const toggleFilterVisibility = () => {
    setIsFilterVisible((prevVisibility) => !prevVisibility);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = (pagination, filters, sorter) => {
    const offset =
      pagination.current * pagination.pageSize - pagination.pageSize;
    const limit = pagination.pageSize;

    setSkip(offset);
    setLimit(limit);
    setPage(pagination.current);
  };

  // const options = [
  //   { value: "sortByDate", label: "Sort by Date" },
  //   { value: "140923", label: "14 09 23" },
  //   { value: "110923", label: "11 09 23" },
  // ];

  const warehouseOptions = [
    { value: "Lobar Handy", label: "Lobar Handy" },
    { value: "Quaint Warehouse", label: "Quaint Warehouse" },
    { value: "Traditional Warehouse", label: "Traditional Warehouse" },
    { value: "Cool Warehouse", label: "Cool Warehouse" },
  ];

  const destinationOptions = [
    { value: "Selosy", label: "Selosy" },
    { value: "Logerro", label: "Logerro" },
    { value: "Vesloo", label: "Vesloo" },
    { value: "Crompy", label: "Crompy" },
  ];

  const columns = [
    {
      title: "#",
      key: "index",
      render: (value, item, index) => (page - 1) * limit + index + 1,
    },
    {
      title: "No SKB",
      dataIndex: "stock_order_code",
      sorter: (a, b) => a.stock_order_code.localeCompare(b.stock_order_code),
      render: (text, item, value) => {
        return (
          <div
            role="button"
            className="me-2 p-2"
            onClick={() =>
              dispatch(
                getTransferStockOutDetail({
                  stock_order_id: item.stock_order_id,
                  stock_order_note: item.stock_order_note,
                  warehouse_name: item.warehouse_name,
                  stock_order_code: item.stock_order_code,
                  stock_order_date: item.stock_order_date,
                  created_by: item.created_by,
                  modal: true,
                })
              )
            }
          >
            <u>{item.stock_order_code}</u>
          </div>
        );
      },
    },
    {
      title: "Destination",
      dataIndex: "warehouse_destination_name",
      width: 200,
      minWidth: 200,
      render: (text, record) => {
        return record.warehouse_destination_name != store.store_name
          ? record.warehouse_destination_name
          : "Current Warehouse";
      },
    },
    {
      title: "Date",
      dataIndex: "stock_order_date",
      width: 150,
      minWidth: 150,
    },
    // {
    //   title: "No SMB",
    //   dataIndex: "sku",
    // },
    {
      title: "Status",
      dataIndex: "stock_order_status",
      width: 150,
      minWidth: 150,
      render: (text, item, value) => {
        return renderStatus(item.status_received);
      },
    },
    // {
    //   title: "User",
    //   dataIndex: "brand",
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, item, value) => (
        <div className="action-table-data">
          <div className="d-flex gap-2">
            <div className="input-block add-lists"></div>
            <div
              role="button"
              className="me-2 p-2"
              onClick={() => {
                const res = dispatch(
                  getTransferStockOutDetail({
                    stock_order_id: item.stock_order_id,
                    stock_order_note: item.stock_order_note,
                    warehouse_name: item.warehouse_name,
                    stock_order_code: item.stock_order_code,
                    stock_order_date: item.stock_order_date,
                    created_by: item.created_by,

                    modal: false,
                  })
                ).unwrap();
              }}
            >
              <Printer className="text-info" />
            </div>
            {item.status_received == "partially received" ||
            item.status_received == "created" ? (
              <div
                role="button"
                className="confirm-text p-2"
                onClick={() => handleDelete(item)}
              >
                <X className="feather-trash-2 text-danger" />
              </div>
            ) : null}
          </div>
        </div>
      ),
      sorter: (a, b) => a.createdby.length - b.createdby.length,
    },
  ];

  const MySwal = withReactContent(Swal);

  const handleDelete = (item) => {
    MySwal.fire({
      title: `Cut off this data? <br/><code>${item.stock_order_code}</code>`,
      text: "",
      showCancelButton: true,
      icon: "warning",
      // cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await dispatch(
          transferStockOutCutOff({ stock_order_id: item.stock_order_id })
        ).unwrap();
        // console.log("confirmed");
      } else {
        // console.log("canceled");
      }
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setQ(search);
  };

  useEffect(() => {
    dispatch(reset());
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content">
        <Header />
        {/* /product list */}
        <div className="card table-list-card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set">
                <form onSubmit={handleSearch}>
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <button className="btn btn-searchset" type="submit">
                      <i data-feather="search" className="feather-search" />
                    </button>
                  </div>
                </form>
              </div>
              {/* <div className="search-path">
                <Link
                  className={`btn btn-filter ${
                    isFilterVisible ? "setclose" : ""
                  }`}
                  id="filter_search"
                >
                  <Filter
                    className="filter-icon"
                    onClick={toggleFilterVisibility}
                  />
                  <span onClick={toggleFilterVisibility}>
                    <ImageWithBasePath
                      src="assets/img/icons/closes.svg"
                      alt="img"
                    />
                  </span>
                </Link>
              </div> */}
              {/* <div className="form-sort stylewidth">
                <Sliders className="info-img" />
                <Select
                  classNamePrefix="react-select"
                  className="img-select"
                  options={options}
                  placeholder="Sort by Date"
                />
              </div> */}
            </div>
            {/* /Filter */}
            <div
              className={`card${isFilterVisible ? " visible" : ""}`}
              id="filter_inputs"
              style={{ display: isFilterVisible ? "block" : "none" }}
            >
              <div className="card-body pb-0">
                <div className="row">
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Archive className="info-img" />
                      <Select
                        className="img-select"
                        classNamePrefix="react-select"
                        options={warehouseOptions}
                        placeholder="Warehouse From"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <User className="info-img" />
                      <Select
                        className="img-select"
                        classNamePrefix="react-select"
                        options={destinationOptions}
                        placeholder="Warehouse To"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <Calendar className="info-img" />
                      <div className="input-groupicon">
                        <DatePicker
                          selected={selectedDate}
                          onChange={handleDateChange}
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Choose Date"
                          className="datetimepicker"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6 col-12 ms-auto">
                    <div className="input-blocks">
                      <a className="btn btn-filters ms-auto">
                        {" "}
                        <i
                          data-feather="search"
                          className="feather-search"
                        />{" "}
                        Search{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Filter */}
            <div className="table-responsive">
              <Table
                tableClassName={"tight-table"}
                className="table datanew"
                columns={columns}
                dataSource={transferStockOut.data}
                loading={transferStockOut.loading}
                total={transferStockOut.total}
                handleChange={handleChange}
                pageSize={limit}
              />
            </div>
          </div>
        </div>
        {/* /product list */}
      </div>
      <AddModal />
      <PrintModal />
    </div>
  );
};

export default StockTransfer;
